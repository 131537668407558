import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "w-screen"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Picture = _resolveComponent("Picture")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Grid = _resolveComponent("Grid")!

  return (_ctx.isGrid)
    ? (_openBlock(), _createBlock(_component_Grid, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, { span: [ 2, 6 ] }, {
            default: _withCtx(() => [
              _createVNode(_component_Picture, {
                image: _ctx.data.assets[0],
                cover: "",
                height: "auto",
                width: "auto",
                maxWidth: "auto"
              }, null, 8, ["image"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Picture, {
          image: _ctx.data.assets[0],
          cover: "",
          width: "100%",
          maxWidth: "auto",
          height: "auto",
          maxHeight: "100%"
        }, null, 8, ["image"])
      ]))
}