

    import { defineComponent } from 'vue'
    import Grid from '../Grid/Grid.vue'
    import Column from '../Grid/Column.vue'
    import Picture from '../Picture.vue'

    export default defineComponent({
        name: 'BlogImage',
        components: {
            Grid,
            Column,
            Picture
        },
        props: {
            data: { type: Object, required: true }
        },
        setup(props) {

            return {
                isGrid: props.data?.style?.value === 'grid',
                isFull: props.data?.style?.value === 'full'
            }

        }
    })

