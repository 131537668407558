

    import { computed, defineComponent } from 'vue'
    import Heading from '../Heading.vue'
    import Paragraph from '../Paragraph.vue'
    import Quote from '../Blog/Quote.vue'
    import Share from '../Blog/Share.vue'
    import LinkToService from '../Blog/LinkToService.vue'
    import BlogImage from '../Blog/BlogImage.vue'
    import Grid from '../Grid/Grid.vue'
    import Column from '../Grid/Column.vue'
    import { Route } from '../../enums/Route'

    const componentNameMap = {
        quote: 'quote',
        heading: 'heading',
        image: 'image',
        paragraph: 'paragraph',
        share: 'share',
        link_to_service: 'LinkToService'
    }

    export default defineComponent({
        name: 'BlogContent',
        components: {
            Heading,
            Paragraph,
            Quote,
            Share,
            BlogImage,
            Grid,
            Column,
            LinkToService
        },
        props: {
            data: { type: Object, required: true },
            index: { type: Number, required: true },
            isLast: { type: Boolean, required: false }
        },
        setup(props) {

            const isQuote = props.data.type === 'quote'
            const isHeading = props.data.type === 'heading'
            const isImage = props.data.type === 'image'
            const isParagraph = props.data.type === 'paragraph'
            const isShare = props.data.type === 'share'
            const isLinkToService = props.data.type === 'link_to_service'

            return {
                isImage,
                isLinkToService,
                component: computed(() => {

                    let content = {
                        is: componentNameMap[props.data.type]
                    } as any

                    if (isQuote) {

                        content = {
                            ...content,
                            params: {
                                author: props.data.author,
                                text: props.data.content,
                                class: [ { 'mt-12': props.index !== 0 } ]
                            }
                        }

                    } else if (isShare) {

                        content = {
                            ...content,
                            params: {
                                title: props.data.title,
                                url: props.data.url,
                                image: props.data.image ?? null,
                                content: props.data.content,
                                name: props.data.shared_by,
                                role: props.data.role,
                                class: [ { 'mt-24': props.index !== 0 } ]
                            }
                        }

                    } else if (isLinkToService) {

                        content = {
                            ...content,
                            params: {
                                title: props.data.title,
                                url: props.data.url,
                                content: props.data.content,
                                button: props.data.button,
                                link: {
                                    name: Route.Services,
                                    params: {
                                        slug: props.data.service.slug
                                    }
                                },
                                class: [ { 'mt-24': props.index !== 0, 'mb-20': !props.isLast } ]
                            }
                        }

                    } else {

                        content = {
                            ...content,
                            html: isHeading ? props.data.heading : props.data.paragraph
                        }

                        if (isHeading) {

                            const headingStyle = props.data.heading_style.value

                            content = {
                                ...content,
                                params: {
                                    tag: headingStyle === null ? 'h2' : headingStyle,
                                    id: props.data.id,
                                    class: [
                                        'overflow-hidden',
                                        { 'pt-20': props.index !== 0 }
                                    ]
                                }
                            }

                        }

                        if (isParagraph) {

                            content = {
                                ...content,
                                params: {
                                    innerSpace: true,
                                    class: {
                                        'mt-10': props.index !== 0
                                    }
                                }
                            }

                        }

                    }

                    return content

                })
            }

        }
    })

