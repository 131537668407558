import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DynamicContent = _resolveComponent("DynamicContent")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Grid = _resolveComponent("Grid")!

  return (_openBlock(), _createBlock(_component_Grid, { class: "page-header" }, {
    default: _withCtx(() => [
      _createVNode(_component_Column, { span: _ctx.headerSpan }, {
        default: _withCtx(() => [
          _createVNode(_component_Heading, {
            tag: "h1",
            class: "text-black",
            innerHTML: _ctx.header
          }, null, 8, ["innerHTML"])
        ]),
        _: 1
      }, 8, ["span"]),
      (_ctx.subHeader || _ctx.$slots.default)
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 0,
            span: _ctx.subHeaderSpan,
            class: "mt-12 md:mt-10"
          }, {
            default: _withCtx(() => [
              (typeof(_ctx.subHeader) === 'array' || typeof(_ctx.subHeader) === 'object')
                ? (_openBlock(), _createBlock(_component_DynamicContent, {
                    key: 0,
                    smaller: _ctx.smaller,
                    content: _ctx.subHeader,
                    "use-new-link-style": _ctx.useNewLinkStyle
                  }, null, 8, ["smaller", "content", "use-new-link-style"]))
                : (_openBlock(), _createBlock(_component_Paragraph, {
                    key: 1,
                    class: "text-gray-600",
                    innerHTML: _ctx.subHeader,
                    smaller: _ctx.smaller
                  }, null, 8, ["innerHTML", "smaller"]))
            ]),
            _: 1
          }, 8, ["span"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}