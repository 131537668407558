import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_BlogImage = _resolveComponent("BlogImage")!

  return (!_ctx.isImage)
    ? (_openBlock(), _createBlock(_component_Grid, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            span: _ctx.isLinkToService ? [ 0 ] : [ 2, 4 ],
            push: _ctx.isLinkToService ? [ 0 ] : [ 0, 1 ]
          }, {
            default: _withCtx(() => [
              (_ctx.component.html)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component.is), _mergeProps({ key: 0 }, _ctx.component.params, {
                    innerHTML: _ctx.component?.html
                  }), null, 16, ["innerHTML"]))
                : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component.is), _normalizeProps(_mergeProps({ key: 1 }, _ctx.component.params)), null, 16))
            ]),
            _: 1
          }, 8, ["span", "push"])
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_BlogImage, {
        class: _normalizeClass({ 'mt-24': _ctx.index !== 0 }),
        data: _ctx.data,
        key: _ctx.index
      }, null, 8, ["class", "data"]))
}