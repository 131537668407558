import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, null, {
    default: _withCtx(() => [
      _createVNode(_component_Grid, { fluid: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            span: [ 2, 4 ],
            push: [ 0, 1 ]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Paragraph, null, {
                default: _withCtx(() => [
                  _createTextVNode("Contents")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_Grid, {
        fluid: "",
        class: "mt-5"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.content, (heading, index) => {
            return (_openBlock(), _createBlock(_component_Column, {
              key: index,
              span: [ 2, 4 ],
              push: [ 0, 1 ],
              class: _normalizeClass(["toc__item", { '--last': index === _ctx.content.length - 1, }])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Paragraph, {
                  class: "toc__item__parent",
                  "no-margin": heading.children && heading.children.length > 0
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_link, {
                      class: "text-12px underline font-bold leading-none",
                      to: { name: 'blog-post', hash: `#${heading.id}` }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(heading.heading), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _: 2
                }, 1032, ["no-margin"]),
                (heading.children && heading.children.length > 0)
                  ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(heading.children, (child, idx) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: idx,
                          class: "toc__item__child"
                        }, [
                          _createVNode(_component_Paragraph, { "no-margin": "" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_router_link, {
                                class: "text-12px underline font-bold leading-none",
                                to: { name: 'blog-post', hash: `#${child.id}` }
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(child.heading), 1)
                                ]),
                                _: 2
                              }, 1032, ["to"])
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1032, ["class"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}