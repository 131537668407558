
    import { computed, defineComponent, ref } from 'vue'
    import { useCms } from '../../compositions/UseCms'
    import Button from './../Button.vue'
    import Heading from '../Heading.vue'
    import Paragraph from '../Paragraph.vue'
    import { Locale } from '../../enums/Locale'

    export default defineComponent({
        name: 'LinkToService',
        components: {
            Button,
            Heading,
            Paragraph
        },
        props: {
            title: { type: String, required: true },
            url: { type: String, required: true },
            content: { type: String, required: true },
            button: { type: String, required: true },
            link: { type: String, required: true }
        },
        setup(props) {

            const isCNLocale = ref(process.env.VUE_APP_LANGUAGE === Locale.CHINESE)

            return {
                isCNLocale
            }

        }
    })

