import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex justify-center ow-post__cover" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Container = _resolveComponent("Container")!
  const _component_Picture = _resolveComponent("Picture")!
  const _component_TableOfContents = _resolveComponent("TableOfContents")!
  const _component_BlogContent = _resolveComponent("BlogContent")!
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Projects = _resolveComponent("Projects")!
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!
  const _component_MetaInfo = _resolveComponent("MetaInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ThemeChanger, {
      to: _ctx.Theme.Default
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Container, {
          fluid: "",
          class: "pt-32 md:pt-48"
        }, {
          default: _withCtx(() => [
            (_ctx.isNormalBlogPost(_ctx.post))
              ? (_openBlock(), _createBlock(_component_PageHeader, {
                  key: 0,
                  header: _ctx.post?.title,
                  "sub-header": `${_ctx.post?.updated_at} · Written by ${_ctx.post?.author?.name ?? _ctx.post?.updated_by?.name ?? ''}`,
                  "header-span": 5
                }, null, 8, ["header", "sub-header"]))
              : (_ctx.isShareBlogPost(_ctx.post))
                ? (_openBlock(), _createBlock(_component_PageHeader, {
                    key: 1,
                    header: _ctx.post?.title,
                    "sub-header": `${_ctx.post?.updated_at}`,
                    "header-span": 5
                  }, null, 8, ["header", "sub-header"]))
                : _createCommentVNode("", true),
            (_ctx.isShareBlogPost(_ctx.post) && _ctx.post.introduction)
              ? (_openBlock(), _createBlock(_component_Grid, { key: 2 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_Paragraph, {
                          class: "pt-12",
                          innerHTML: _ctx.post.introduction
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_ctx.post.cover)
          ? (_openBlock(), _createBlock(_component_Grid, {
              key: 0,
              fluid: "",
              class: "pt-16 md:pt-24"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_Picture, {
                    image: _ctx.post.cover,
                    height: "100%",
                    maxHeight: "100%",
                    width: "100%",
                    maxWidth: "100%",
                    lazy: false
                  }, null, 8, ["image"])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          (_ctx.tableOfContents && _ctx.tableOfContents.length > 0)
            ? (_openBlock(), _createBlock(_component_TableOfContents, {
                key: 0,
                class: "mt-12 md:mt-20",
                content: _ctx.tableOfContents
              }, null, 8, ["content"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Container, {
            class: "mt-16 md:mt-24",
            fluid: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.post.body, (item, index) => {
                return (_openBlock(), _createBlock(_component_BlogContent, {
                  key: index,
                  data: item,
                  index: index,
                  isLast: index === _ctx.post.body.length - 1
                }, null, 8, ["data", "index", "isLast"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        (_ctx.post?.related_cases_heading || _ctx.post?.related_cases_title || (_ctx.post?.related_cases && _ctx.post?.related_cases.length > 0))
          ? (_openBlock(), _createBlock(_component_Container, {
              key: 1,
              fluid: ""
            }, {
              default: _withCtx(() => [
                (_ctx.post?.related_cases_heading)
                  ? (_openBlock(), _createBlock(_component_Grid, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Column, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_Paragraph, {
                              class: "mt-24 md:mt-48",
                              innerHTML: _ctx.post?.related_cases_heading,
                              heading: ""
                            }, null, 8, ["innerHTML"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.post.related_cases_title)
                  ? (_openBlock(), _createBlock(_component_Grid, {
                      key: 1,
                      class: _normalizeClass([ {
                    'sm:mt-12 md:mt-16': _ctx.post?.related_cases_heading,
                    'sm:mt-24 md:mt-48': !_ctx.post?.related_cases_heading
                } ])
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Column, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_Heading, {
                              tag: "h2",
                              innerHTML: _ctx.post.related_cases_title
                            }, null, 8, ["innerHTML"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["class"]))
                  : _createCommentVNode("", true),
                (_ctx.post?.related_cases && _ctx.post?.related_cases.length > 0)
                  ? (_openBlock(), _createBlock(_component_Projects, {
                      key: _ctx.post?.slug,
                      data: _ctx.post?.related_cases,
                      class: _normalizeClass({
                    'sm:mt-12 md:mt-16': (_ctx.post.related_cases_heading || _ctx.post.related_cases_title),
                    'sm:mt-24 md:mt-48': (!_ctx.post.related_cases_heading && !_ctx.post.related_cases_title),
                })
                    }, null, 8, ["data", "class"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["to"]),
    (_ctx.post.seo)
      ? (_openBlock(), _createBlock(_component_MetaInfo, {
          key: 0,
          seo: _ctx.post.seo,
          "structured-data": _ctx.post.seo_structured_data,
          published: _ctx.post.date,
          modified: _ctx.post.last_modified
        }, null, 8, ["seo", "structured-data", "published", "modified"]))
      : _createCommentVNode("", true)
  ], 64))
}