import { BlogHeadingInterface, BlogPostInterface } from '../Interfaces/BlogPostInterface'

/**
 * @file UseTableOfContents.ts
 * @description Generates an array of all the headings in a blog post and nests child h3 & h4 header objects into an array of the parent h2 object
 */
export function useTableOfContents(blogPost: BlogPostInterface) {

    let prevHeading = null as BlogHeadingInterface | null
    const tableOfContent = [] as Array<BlogHeadingInterface>
    let subHeaders = [] as Array<BlogHeadingInterface>
    const subHeaderTypes = [ 'h3', 'h4' ]

    const filteredHeadings = blogPost.body.filter(item => item.type === 'heading') as Array<BlogHeadingInterface>

    for (const item of filteredHeadings) {

        // if item is last add children to it
        if (filteredHeadings.indexOf(item) === filteredHeadings.length - 1) {

            if (prevHeading) {

                prevHeading.children = subHeaders

            }

        }

        if (item.heading_style.value === 'h2' || item.heading_style.value == null) {

            if (prevHeading) {

                prevHeading.children = subHeaders
                subHeaders = []

            }

            prevHeading = item
            tableOfContent.push(item)

        } else if (subHeaderTypes.includes(item.heading_style.value)) {

            subHeaders.push(item)

        }

    }

    return tableOfContent

}
