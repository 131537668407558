

    import { defineComponent } from 'vue'
    import Anchor from '../Anchor.vue'
    import Heading from '../Heading.vue'
    import ExternalLink from '../Icons/ExternalLink.vue'
    import Picture from '../Picture.vue'
    import Paragraph from '../Paragraph.vue'
    import { useCms } from '../../compositions/UseCms'
    import { isCNLocale } from '../../compositions/useI18n'

    export default defineComponent({
        name: 'Share',
        components: {
            Anchor,
            ExternalLink,
            Heading,
            Picture,
            Paragraph
        },
        props: {
            title: { type: String, required: true },
            url: { type: String, required: true },
            image: { type: Array, required: false },
            content: { type: [ String, Array ], default: null },
            name: { type: String, required: true },
            role: { type: String, required: true }
        },
        setup() {

            return {
                isCNLocale
            }

        }
    })

