import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-87a76528"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pt-19 link-to-service" }
const _hoisted_2 = { class: "flex flex-col md:flex-row mt-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Heading, {
      tag: "h3",
      class: "header"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Paragraph, { class: "mr-16 content sm:mr-0" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.content), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Button, {
        pinkSolid: "",
        class: "button",
        url: _ctx.link,
        large: ""
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.button), 1)
        ]),
        _: 1
      }, 8, ["url"])
    ])
  ]))
}