

    import { defineComponent } from 'vue'
    import Paragraph from '../Paragraph.vue'

    export default defineComponent({
        name: 'Quote',
        components: {
            Paragraph
        },
        props: {
            text: { type: String, required: true },
            author: { type: String, required: false }
        }
    })

