

    import { computed, defineComponent, PropType } from 'vue'
    import { ProjectInterface } from '../Interfaces/ProjectInterface'
    import Column from './Grid/Column.vue'
    import Grid from './Grid/Grid.vue'
    import Anchor from './Anchor.vue'
    // import BlockRevealAnimation from './BlockRevealAnimation.vue'
    import ClippingAnimation from './ClippingAnimation.vue'
    import Container from './Container.vue'
    import CustomCursor from './CustomCursor.vue'
    import Heading from './Heading.vue'
    import Paragraph from './Paragraph.vue'
    import Picture from './Picture.vue'
    // import LoopingVideo from './LoopingVideo.vue'
    import { Route } from '../enums/Route'
    import { Theme } from '../enums/Theme'
    import chunk from 'lodash.chunk'
    import { useCms } from '@/compositions/UseCms.ts'

    export default defineComponent({
        name: 'Projects',
        props: {
            data: {
                type: Array as PropType<ProjectInterface[]>,
                required: true
            },
            secondColumnOffset: {
                type: Boolean,
                default: false
            },
            useH2ForTitle: {
                type: Boolean,
                default: false
            }
        },
        components: {
            Anchor,
            // BlockRevealAnimation,
            ClippingAnimation,
            Grid,
            Column,
            CustomCursor,
            Container,
            Heading,
            Picture,
            Paragraph
            // LoopingVideo
        },
        setup(props) {

            const { isCNLocale } = useCms()

            const comingSoonText = computed(() => isCNLocale.value ? '即将到来' : 'Coming Soon')
            const viewText = computed(() => isCNLocale.value ? '查看' : 'View')

            return {
                isCNLocale,
                comingSoonText,
                viewText,
                Route,
                Theme,
                chunkedData: chunk(props.data, 2),
                formattedTags(tags: string[]) {

                    return tags.join(' / ')

                }
            }

        }
    })

