

    import { defineComponent } from 'vue'
    import Container from '../components/Container.vue'
    import Grid from '../components/Grid/Grid.vue'
    import Column from '../components/Grid/Column.vue'
    import PageHeader from '../components/PageHeader.vue'
    import Picture from '../components/Picture.vue'
    import Heading from '../components/Heading.vue'
    import Projects from '../components/Projects.vue'
    import TableOfContents from '../components/Blog/TableOfContents.vue'
    import BlogContent from '../components/Blog/BlogContent.vue'
    // import SocialLinks from '../components/Blog/SocialLinks.vue'
    import MetaInfo from '../components/MetaInfo.vue'
    import ThemeChanger from '../components/ThemeChanger.vue'
    import { Theme } from '../enums/Theme'
    import { Route } from '../enums/Route'
    import { useCms } from '../compositions/UseCms'
    import { useTheme } from '../compositions/UseTheme'
    import { useRoute } from 'vue-router'
    import { useBlogType } from '../compositions/UseBlogType'
    import Paragraph from '../components/Paragraph.vue'
    import { useTableOfContents } from '../compositions/UseTableOfContents'
    import { BlogPostInterface } from '../Interfaces/BlogPostInterface'
    // import { stickComponentOnScroll } from '../compositions/behaviors/StickComponentOnScroll'
    // import { Breakpoint } from '../enums/Breakpoint'
    // import { currentActiveBreakPoint } from '../compositions/UseResponsiveValue'

    export default defineComponent({
        name: 'BlogPost',
        components: {
            Paragraph,
            Container,
            Grid,
            Column,
            BlogContent,
            Heading,
            PageHeader,
            // SocialLinks,
            Picture,
            Projects,
            TableOfContents,
            MetaInfo,
            ThemeChanger
        },
        // Remove it, not sure if it's necessary
        // async beforeRouteEnter(to, from, next) {

        //     (await useCms()).blogData(to.params.slug as string) ? next() : next({ name: Route.NotFound })

        // },
        setup() {

            const route = useRoute()
            const { blogData } = useCms()
            const post = blogData(route.params.slug) as BlogPostInterface

            const { isNormalBlogPost, isShareBlogPost } = useBlogType()
            useTheme().initializeCurrentThemeBasedOnRouteMeta()
            const tableOfContents = useTableOfContents(post)

            // const startRef = ref<HTMLElement>()
            // const endRef = ref<HTMLElement>()

            // const { wrapperReference, stickyElementReference, offsetStyle } = stickComponentOnScroll({
            //     top: 100,
            //     bottom: 0
            // }, false, [ startRef, endRef ])

            // const isVertical = computed(() => currentActiveBreakPoint.value !== Breakpoint.SM)

            return {
                Theme,
                post,
                // startRef,
                // endRef,
                // wrapperReference,
                // stickyElementReference,
                // offsetStyle,
                isNormalBlogPost,
                isShareBlogPost,
                tableOfContents
                // isVertical
            }

        }
    })

