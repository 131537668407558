import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "quote pl-5 py-2 md:py-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Paragraph = _resolveComponent("Paragraph")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Paragraph, { innerHTML: _ctx.text }, null, 8, ["innerHTML"]),
    (_ctx.author)
      ? (_openBlock(), _createBlock(_component_Paragraph, {
          key: 0,
          class: "mt-8"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" - " + _toDisplayString(_ctx.author), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}