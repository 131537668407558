import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "dynamic-content inline-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.components, (component, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (component.is === 'a' || component.is === 'Anchor')
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(component.is), _normalizeProps(_mergeProps({ key: 0 }, component.params)), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(component.label), 1)
              ]),
              _: 2
            }, 1040))
          : (component.html)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(component.is), _mergeProps({ key: 1 }, component.params, {
                innerHTML: component?.html
              }), null, 16, ["innerHTML"]))
            : (_openBlock(), _createBlock(_resolveDynamicComponent(component.is), _normalizeProps(_mergeProps({ key: 2 }, component.params)), null, 16))
      ], 64))
    }), 128))
  ]))
}