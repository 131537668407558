

    import { defineComponent, PropType } from 'vue'
    import Container from '../Container.vue'
    import Grid from '../Grid/Grid.vue'
    import Column from '../Grid/Column.vue'
    import Paragraph from '../Paragraph.vue'
    import { BlogHeadingInterface } from '../../Interfaces/BlogPostInterface'

    export default defineComponent({
        name: 'TableOfContents',
        components: {
            Container,
            Paragraph,
            Grid,
            Column
        },
        props: {
            content: { type: Array as PropType<BlogHeadingInterface[]>, required: true }
        }
    })

